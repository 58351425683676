import { TemplateHeader } from '@canalplus/mycanal-sharedcomponent';
import { ApiV2OnClick } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import Linker from '../Linker/Linker';

export type TemplateHeaderCanalProps = {
  title: string;
  subtitle?: string;
  button?: { title?: string; onClick?: ApiV2OnClick };
  displayBigTitle?: boolean;
  isFromDetail?: boolean;
  isFromShowcase?: boolean;
  theme?: {
    templateHeader?: string;
    templateHeader__link?: string;
    templateHeader__title?: string;
    templateHeader__subtitle?: string;
  };
};

/**
 * TemplateHeader
 *
 * @param title           title to display to the left
 * @param displayBigTitle switch to have a bigger font on title
 * @param button          link to display to the right
 */
function TemplateHeaderCanal({
  title,
  button,
  displayBigTitle = false,
  subtitle,
  isFromDetail = false,
  isFromShowcase = false,
  theme,
}: TemplateHeaderCanalProps): JSX.Element {
  return (
    <TemplateHeader
      button={button}
      displayBigTitle={displayBigTitle}
      isFromDetail={isFromDetail}
      isFromShowcase={isFromShowcase}
      Linker={Linker}
      subtitle={subtitle}
      title={title}
      theme={theme}
    />
  );
}

export default TemplateHeaderCanal;

import { DIMENSIONS } from '@canalplus/mycanal-commons';
import { useSelector } from 'react-redux';
import { displayTVModeSelector } from '../../../store/slices/displayMode-selectors';
import { isPhoneResolutionSelector } from '../../../store/slices/ui-selectors';
import CoverImage from '../../CoverImage/CoverImage';
import { CoverV5Data } from '../types';
import styles from './CoverV5.css';

export interface ICoverProps {
  cover: CoverV5Data;
}

function CoverV5({ cover }: ICoverProps): JSX.Element {
  const isTvDevice = useSelector(displayTVModeSelector);
  const isCompact = useSelector(isPhoneResolutionSelector);

  return (
    <section className={styles['cover--v5']} data-testid="CoverV5">
      <CoverImage
        dimensions={DIMENSIONS.COVER_IMAGE}
        image={{ default: cover.image, mobile: cover.imageMobile }}
        onClickData={cover}
        title={cover.alt}
        isMobileOnly={isCompact}
        isDesktopOnly={!isCompact}
        isTvDevice={isTvDevice}
        isArtDirection
      />
    </section>
  );
}

export default CoverV5;

import { useSelector } from 'react-redux';
import { displayTVModeSelector } from '../../store/slices/displayMode-selectors';
import CoverDeprecated from './CoverDeprecated/CoverDeprecated';
import CoverV5 from './CoverV5/CoverV5';
import { CoverData, CoverV5Data } from './types';

export const isCoverV5 = (cover?: CoverData): cover is CoverV5Data => {
  return (
    !!cover &&
    ('imageMobile' in cover ||
      'urlLogoType' in cover ||
      'urlLogoChannel' in cover)
  );
};

type IdentityCoverProps = {
  cover?: CoverData;
};

export function IdentityCover({
  cover,
}: IdentityCoverProps): JSX.Element | null {
  const isTvDevice = useSelector(displayTVModeSelector);

  if (!cover?.image) {
    return null;
  }

  if (isCoverV5(cover)) {
    return <CoverV5 cover={cover} />;
  }

  return !isTvDevice ? <CoverDeprecated cover={cover} /> : null;
}
